//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

export default {
    props: {
        title: {
            type: String,
            required: true,
        },
        content: {
            type: Object,
            required: true,
        },
        imageUrl: {
            type: String,
            required: false,
        },
        imageTitle: {
            type: String,
            required: false,
        },
        imageAlt: {
            type: String,
            required: false,
        },
        linkUrl: {
            type: String,
            required: false,
            default: '',
        },
        linkTitle: {
            type: String,
            required: false,
            default: '',
        },
        imageRight: {
            type: Boolean,
            required: false,
            default: true,
        },
        background: {
            type: String,
            required: true,
            default: 'Light',
        },
        filetype: {
            type: String,
            required: false,
            default: '',
        },
        imageDescription: {
            type: String,
            required: false,
            default: '',
        },
    },
    data() {
        return {
            tagType: 'h2',
        };
    },
    computed: {
        colors() {
            switch (this.background) {
                case 'Accent':
                    return 'md:bg-tertiary md:text-black';
                case 'Accent-Dark':
                    return 'md:bg-[#2D3D47]';
                default:
                    return 'md:bg-white text-black';
            }
        },
    },
    mounted() {
        if (process.client) {
            this.tagType = document.querySelectorAll('h1').length ? 'h2' : 'h1';
        }
    },
};
