//
//
//
//
//

import { BLOCKS, INLINES } from '@contentful/rich-text-types';
import RichTextRenderer from 'contentful-rich-text-vue-renderer';

export default {
    components: {
        RichTextRenderer,
    },
    props: {
        content: {
            type: Object,
            required: true,
        },
    },
    methods: {
        /**
             * All possible
             *
             * #BLOCKS
             DOCUMENT
             PARAGRAPH
             HEADING_1
             HEADING_2
             HEADING_3
             HEADING_4
             HEADING_5
             HEADING_6
             UL_LIST
             OL_LIST
             LIST_ITEM
             QUOTE
             HR
             EMBEDDED_ENTRY
             EMBEDDED_ASSET

             #INLINES
             EMBEDDED_ENTRY (this is different from the BLOCKS.EMBEDDED_ENTRY)
             HYPERLINK
             ENTRY_HYPERLINK
             ASSET_HYPERLINK
             */
        renderNodes() {
            return {
                [BLOCKS.PARAGRAPH]: (node, key, h, next) =>
                    h(
                        'p',
                        {
                            key,
                            class: {
                                'mb-2': true,
                            },
                        },
                        next(node.content, key, h, next),
                    ),
                [BLOCKS.QUOTE]: (node, key, h, next) =>
                    h(
                        'div',
                        {
                            key,
                            class: {
                                'border-l-2 border-primary': true,
                                'my-10 pl-4 font-bold text-xl leading-7': true,
                            },
                        },
                        next(node.content, key, h, next),
                    ),
                [BLOCKS.HEADING_1]: (node, key, h, next) =>
                    h(
                        'opus-title',
                        {
                            key,
                            attrs: { tag: 'h2', size: 'h1' },
                            class: {
                                'mb-4': true,
                            },
                        },
                        next(node.content, key, h, next),
                    ),
                [BLOCKS.HEADING_2]: (node, key, h, next) =>
                    h(
                        'opus-title',
                        {
                            key,
                            attrs: { tag: 'h2', size: 'h2' },
                            class: {
                                'mb-4': true,
                            },
                        },
                        next(node.content, key, h, next),
                    ),
                [BLOCKS.HEADING_3]: (node, key, h, next) =>
                    h(
                        'opus-title',
                        {
                            key,
                            attrs: { tag: 'h3', size: 'h3' },
                            class: {
                                'mb-4': true,
                            },
                        },
                        next(node.content, key, h, next),
                    ),
                [BLOCKS.HEADING_4]: (node, key, h, next) =>
                    h(
                        'p',
                        {
                            key,
                            class: {
                                'font-sm mb-2': true,
                            },
                        },
                        next(node.content, key, h, next),
                    ),
                [BLOCKS.EMBEDDED_ASSET]: (node, key, h, next) =>
                    h(
                        'div',
                        {
                            key,
                            class: {
                                'relative aspect-w-16 aspect-h-9 my-10': true,
                            },
                        },
                        [
                            h('img', {
                                class: {
                                    'w-full h-full object-center object-cover ': true,
                                },
                                attrs: {
                                    alt: node.data.target.fields.title,
                                    src: this.$options.filters.ctflimage(node.data.target.fields.file.url, 720, 405),
                                    loading: 'lazy',
                                },
                            }),
                        ],
                    ),
                [INLINES.HYPERLINK]: (node, key, h, next) => {
                    if (node.data.uri && node.data.uri.includes('youtube.com/embed/')) {
                        return h(
                            'div',
                            {
                                class: 'w-full aspect-w-16 aspect-h-9 rounded-lg shadow-lg my-6',
                            },
                            [
                                h('iframe', {
                                    key,
                                    attrs: {
                                        class: 'rounded-lg shadow-lg',
                                        src: node.data.uri,
                                        title: 'YouTube video player',
                                        frameborder: '0',
                                        allow: 'accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture',
                                        allowfullscreen: true,
                                    },
                                }),
                            ],
                        );
                    } else {
                        return h(
                            'a',
                            {
                                key,
                                class: {
                                    'font-bold hover:text-primary transition-colors': true,
                                },
                                attrs: {
                                    href: node.data.uri,
                                },
                            },
                            next(node.content, key, h, next),
                        );
                    }
                },
                [BLOCKS.LIST_ITEM]: (node, key, h, next) =>
                    h(
                        'li',
                        {
                            key,
                        },
                        [
                            h(
                                'div',
                                {
                                    class: {
                                        flex: true,
                                    },
                                },
                                [
                                    h('OpusIcon', {
                                        props: {
                                            classNames: 'text-primary mr-2 mb-2 w-4 h-4 shrink-0 mt-1',
                                            code: 'check',
                                        },
                                    }),
                                    next(node.content, key, h, next),
                                ],
                            ),
                        ],
                    ),
            };
        },
    },
};
