import { render, staticRenderFns } from "./ImageBanner.vue?vue&type=template&id=1481cbb2&"
import script from "./ImageBanner.vue?vue&type=script&lang=js&"
export * from "./ImageBanner.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {ContentfulImageBannerHero: require('/~/src/modules/contentful/components/ImageBanner/ImageBannerHero.vue').default,ContentfulImageBannerSmallBanner: require('/~/src/modules/contentful/components/ImageBanner/ImageBannerSmallBanner.vue').default,ContentfulImageBannerHalfImage: require('/~/src/modules/contentful/components/ImageBanner/ImageBannerHalfImage.vue').default})
